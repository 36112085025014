<template>
    <div id="contact">
        <PageTitle :title="title" />
        <div class="container">
            <div class="info">
                <a href="tel:+310.492.0737">
                    310.492.0737
                </a>
                <a href="mailto:info@paulsalazargroup.com">
                    info@paulsalazargroup.com
                </a>
                <hr>
                <p>
                    257 North Cañon Drive<br>
                    Beverly Hills, CA 90210<br>
                    CalBRE# 01160681
                </p>
            </div>
            <div class="image-container">
                <img src="/images/contact.jpg" alt="" srcset="">
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from "../components/PageTitle"

export default {
    name: "Contact",
    components: {
        PageTitle
    },
    data(){
        return{
            title: "Contact",
            marker: {
                lat: 34.0686007, 
                lng: -118.4016119,
            },
            phone: "310.492.0737",
            address: {
                name: "Hilton & Hyland",
                street: "257 North Canon Drive",
                city: "Beverly Hills",
                zipcode: "CA 90210"
            },
            email: "info@paulsalazargroup.com",
            drenumber: "02032653"
        }
    },
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description', 
            content: "Paul Salazar"
        }]
    },
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    background: #000;
    padding: 2rem 0;
    padding-top: 4rem;
    align-items: center;
    .image-container{
        width: 50%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .info{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4rem 0;
        a{
            font-size: 18px;
            letter-spacing: 2px;
            color: #fff;
            text-decoration: none;
            margin: 1rem 0;
            margin-left: 30%;
        }
        p{
            font-size: 18px;
            letter-spacing: 2px;
            color: #fff;
            margin: 0;
            margin-left: 30%;
            br {
                content: " ";
                display: block;
                margin: 8px 0;
            }
        }
        hr{
            border: none;
            height: 1px;
            /* Set the hr color */
            color: #fff; /* old IE */
            background-color: rgba(255,255,255,0.25); /* Modern Browsers */
            width: 60%;
            margin: 1.75rem 0;
        }
        .mail{
            padding-top: 2rem;
        }
    }
}
@media (max-width: $mobile) {
    .container{
        padding-top: 0;
        flex-wrap: wrap;
        .image-container{
            width: 100%;
        }
        .info{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 3rem 0;
            a{
                margin: 1rem auto;
            }
            p{
                margin: 0 auto;
            }
            hr{
                width: 80%;
                margin: 1.75rem auto;
            }
        }
    }
}
</style>
